@import "variables.less";
@import "mixins.less";
@import "mixins.less";

html, body {
    height: 100%;
    font-family: 'Open Sans',sans-serif;
    font-weight: 400;
    color: #555;
    font-size: 14px;
    margin: 0;
}

body {
    background-color: #f1f1f1;
    overflow: hidden;
}

.ui-view-container {
    height: 100%;
    min-height: 100%;

    .ui-view-child {
        height: 100%;
        min-height: 100%;
    }
}

#column-menu {
    background-color: #fff;
    width: 250px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 2;
    .box-shadow(0 1px 1px rgba(0, 0, 0, 0.15));

    #header {
        text-align: center;
        padding: 15px 0;
        height: 111px;

        img {
            width: 132px;
        }
    }

    #menu {
        padding: 0;
        font-size: 16px;
        height: ~"calc(100% - 170px)";
        overflow: auto;

        #user-name {
            position: relative;
            padding: 10px 0 10px 60px;
            color: #111;
            background-color: #f9f9f9;
            border-top: 1px solid #f1f1f1;
            border-bottom: 1px solid #f1f1f1;

            .icon {
                display: block;
                position: absolute;
                top: 7px;
                left: 20px;
                width: 28px;
                height: 28px;
                line-height: 28px;
                text-align: center;
            }

            #signout:hover {
                background-color: #f9f9f9;
            }

            #signout:active {
                background-color: #f1f1f1;
            }
        }

        li {
            position: relative;

            > a {
                position: relative;
                display: flex;
                align-items: center;
                //display: block;
                padding-left: 60px;
                line-height: 44px;

                .icon {
                    display: block;
                    position: absolute;
                    top: 8px;
                    left: 20px;
                    width: 28px;
                    height: 28px;
                    line-height: 28px;
                    text-align: center;
                }
            }

            a:hover {
                background-color: #f9f9f9;
                .box-shadow(inset -3px 0px 0px 0px @main);
            }

            .badge {
                margin-left: 10px;
                background-color: #fd234d;
                position: absolute;
                right: 35px;
                top: 14px;
            }

            .glyphicon-search {
              margin-left: auto;
              margin-right: 5px;
              width: 23px;
              height: 23px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #FF2148;
              border-radius: 4px;
              color: white;
              font-size: .8rem;
            }
        }

        li.active {
            a {
                .box-shadow(inset -3px 0px 0px 0px @main);
                background-color: #fafafa;
            }
        }

        li.submenu {
            background-color: #fff;

            .badge {
                top: 7px;
            }

            > ul {
                margin: 0;
                font-size: 13px;
                list-style-type: none;
                padding: 0;

                > li {
                    > a {
                        line-height: 34px;
                        font-weight: normal;
                        display: block;
                        color: #777;
                        padding-left: 60px;
                        @shadow: inset 0 -1px #f5f5f5;
                        .box-shadow(@shadow);
                    }

                    a:hover {
                        color: #222;
                        @shadow: inset 0 -1px #f5f5f5, inset 0 -1px #f5f5f5, inset -3px 0px 0px 0px @main;
                        .box-shadow(@shadow);
                    }
                }

                li.active {
                    a {
                        color: #222;
                        @shadow: inset 0 -1px #f5f5f5, inset -3px 0px 0px 0px @main;
                        .box-shadow(@shadow);
                    }
                }
            }
        }

        hr {
            margin: 10px 0;
        }

        #account-confirm {
            padding: 15px;
            text-align: center;

            .btn {
                width: 100%;

                .glyphicon {
                    float: left;
                }
            }
        }
    }

    #signout {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 7px;
        font-size: 16px;
        background-color: #f3f3f3;
        border-top: 1px solid #ddd;
        width: 100%;
        padding-left: 60px;
        line-height: 44px;

        .glyphicon {
            display: block;
            position: absolute;
            top: 17px;
            padding: 5px;
            left: 20px;
            width: 28px;
            height: 28px;
            text-align: center;
        }

    }
}

#column-content {
    width: calc(~'100% - 250px');
    position: absolute;
    background-color: #f1f1f1;
    left: 250px;
    top: 0;
    .adjusted();
    overflow-y: auto !important;

    #container-content {
        height: 100%;
        min-height: 100%;
        padding: 21px 15px 0 25px;

        .content-header {
            margin-bottom: 15px;
            padding-bottom: 15px;

            h2 {
                font-size: 18px;
                margin: 0;
                padding: 0 20px;
                color: #666;
                text-transform: uppercase;
            }

            .redirect {
                margin-right: 10px;
                color: #666;
            }

            a.btn {
                margin-top: -8px;
            }
        }

        .actions {
            margin-bottom: 10px;
            text-align: right;
        }

        .content {
            padding: 20px;
            background: #fff;
            .box-shadow(0 1px 1px rgba(0, 0, 0, 0.15));

            h2 {
                font-size: 15px;
                margin: -20px -20px 20px;
                border-bottom: 1px solid #f5f5f5;
                padding: 15px 20px 13px;
                background: #fff;
                color: #999;
            }

            h3 {
                font-size: 15px;
                margin: 0 -20px;
                padding: 12px 20px;
                border-bottom: 1px solid #f1f1f1;
                border-top: 1px solid #f1f1f1;
                background-color: #f9f9f9;
            }

            .table {
                margin: 0 -20px;
                width: calc(~'100% + 40px');
                max-width: calc(~'100% + 40px');

                td, th {
                    padding: 15px 0 !important;
                }

                td.action {
                    padding: 10px 0 !important;
                }

                td.click {
                    color: @main;
                    cursor: pointer;
                }

                th:first-child,
                td:first-child {
                    padding-left: 20px !important;
                }

            }

            .actions {
                position: absolute;
                right: 20px;
                top: 4px;

                > li {
                    position: relative;
                    padding: 0 2px;
                    display: inline-block;

                    > a {
                        width: 35px;
                        height: 35px;
                        display: inline-block;
                        text-align: center;
                        padding-top: 7px;
                        position: relative;
                    }
                }
            }

            .actions > li > a:before,
            .actions > a:before {
                left: 0;
                top: 0;
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.1);
                z-index: 0;
                border-radius: 50%;
                opacity: 0;
                .transition(.250s);
                .visibility(hidden);
                .transform(scale3d(0, 0, 0));
            }

            .actions > li > a:hover:before,
            .actions > a:hover:before,
            .actions > li > a.open:before,
            .actions > a.open:before,
            .actions > li.active > a:before {
                .transform(scale3d(1, 1, 1));
                opacity: 1;
            }

        }

        form {
            h3 {
                margin-bottom: 20px !important;
            }

            .form-footer {
                width: calc(~'100% - 250px');
                left: 250px;
                position: fixed;
                padding: 10px 40px;
                z-index: 999;
                bottom: 0;
                right: 0;
                .box-shadow(0 -1px 1px rgba(0, 0, 0, 0.15));
                background-color: #f9f9f9;
            }

            .form-pending {
                color: #fff;
                font-size: 17px;
                background-color: @main;
                padding-left: 24px;

                img {
                    margin-right: 15px;
                }
            }

            .form-confirm {
                width: calc(~'100% - 250px');
                left: 250px;
                position: fixed;
                padding: 10px 40px;
                width: 100%;
                z-index: 999;
                bottom: 54px;
                right: 0;
                background-color: @secondary;
                border-top: 1px solid darken(@secondary, 2%);
                color: #fff;
            }
        }

        .action-confirm {
            width: calc(~'100% - 250px');
            left: 250px;
            position: fixed;
            padding: 10px 40px;
            width: 100%;
            z-index: 999;
            bottom: 0;
            right: 0;
            background-color: @secondary;
            border-top: 1px solid darken(@secondary, 2%);
            color: #fff;
        }
    }
}

#footer {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 20px;
    font-size: 13px;
    color: #999;
}

.has-footer {
    margin-bottom: 60px;
}

.disabled {
  pointer-events: none;
  opacity: .5;
}

.nopadding {
  padding: 0;
}

.no-gutter > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
