@import "../variables.less";
@import "../mixins.less";

.place {
    padding: 0 !important;
    margin-bottom: 15px;
    min-height: 200px;

    .row {
        margin: 0;

        .place-preview,
        .place-content {
            margin: 0;
            padding: 0;
            height: 200px;
            position: relative;

            .place-img {
                background-position: bottom;
                background-size: cover;
                width: 100%;
                height: 200px;
            }
        }

        .place-preview {
            border-right: 1px solid #f1f1f1;
            position: relative;
            text-align: center;

            .img-placeholder {
                font-size: 55px;
                color: #888;
                padding: 71px 0;
            }
        }

        .place-header {
            border-bottom: 1px solid #eee;
            width: 100%;
            padding: 12px 12px 12px 15px;
            min-height: 45px;

            .name {
                margin-right: 20px;
            }
        }
    }

    .place-description {
        padding: 15px;

        .progress {
            .box-shadow(none);
            max-width: 400px;
            height: 8px;
            margin-bottom: 0;
        }

        .progress-text {
            color: @secondary;
        }

        .description {
            min-height: 20px;
            padding: 10px 0;
            font-size: 16px;
            max-height: 64px;
            overflow: auto;
        }
    }

    .actions-bottom {
        bottom: 0;
        width: 100%;
        border-top: 1px solid #f2f2f2;
        text-align: right;
        height: auto;
        position: absolute;

        #btn-complete {
            color: @main;
            background-color: #fff;
            margin: 8px 14px 7px;
            float: left;
            padding: 5px 12px;
            .box-shadow(none);
            .transition(.1s);
        }

        #btn-complete:hover {
            background-color: @main;
            color: #fff;
        }

        ul {
            margin: 0;

            li {
                display: inline-block;

                a {
                    display: block;
                    padding: 12px 25px;
                    min-width: 140px;
                    text-align: center;
                    border-top: 3px solid #fff;
                }
            }

            li.active {
                a {
                    border-top-color: @main;
                }
            }
        }
    }
}

.place-view {
    height: calc(~'100% - 304px');
    min-height: calc(~'100% - 304px');
}

@media (max-width: 767px) {

    .place {
        .row {

            .place-content {
                height: auto;

                .place-description {
                    .description {
                        max-height: 80px;
                        margin-bottom: 0;
                    }
                }

                .actions-bottom {
                    padding: 0;
                    position: initial;

                    #btn-complete {
                        margin: 5px !important;
                        width: calc(~'100% - 10px');
                    }

                    ul {
                        padding: 0;

                        li {
                            border-top: 1px solid #eee;
                            width: 50%;
                            float: left;
                        }
                    }
                }
            }
        }
    }

    .place-view {
        height: auto;
        min-height: 0;
    }

    .booking-line {
        div {
            text-align: left;
            padding: 8px 20px;

            label,
            span {
                position: absolute;
                right: 20px;
                top: 11px;
            }
        }
    }
}

.place-row {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 12px;
}

.thumbnail-placeholder {
    font-size: 32px;
    color: #888;
    padding-top: 16px;
    text-align: center;
}

.place-thumb {
    width: 120px;
    flex-shrink: 0;
    height: 64px;
    margin: 5px;
    position: relative;

    >div {
        height: 100%;
        width: 100%;
        background-size: cover;
    }
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(170, 170, 170, 0.188);
    }
}

.place-info {
    margin-left: 10px;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.place-reservations {
    flex-basis: 15%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
}

.place-status {
    flex-basis: 15%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
}

.place-actions {
    flex-basis: 10%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
}

.place-action {
    padding-left: 1em;
    text-align: center;
}

#availabilities {
    overflow: auto;
    border-top: 1px solid #ddd;
    .adjusted(70px);

    .bootstrap-switch-handle-on.bootstrap-switch-primary {
        background-color: @secondary;
        color: #fff;
    }

    .bootstrap-switch-handle-off.bootstrap-switch-default {
        background-color: #333;
        color: #fff;
    }

    .bootstrap-switch {
        .box-shadow(none);
        border-color: #ddd;
        .border-radius(2px);
    }

    #calendar {
        webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        #toolbar {
            padding: 5px;
            line-height: 35px;
            font-weight: 600;
        }

        .thead {
            margin: 0;

            > div {
                text-align: center;
                padding: 7px;
                border-top: 1px solid #ddd;
                width: calc(~'100% / 7');
            }
        }

        .tbody {
            border-left: 1px solid #ddd;
            border-top: 1px solid #ddd;

            .selecting {
                cursor: move;
            }

            .row {
                margin: 0;

                .day {
                    height: 110px;
                    text-align: center;
                    padding: 0;
                    border-right: 1px solid #ddd;
                    border-bottom: 1px solid #ddd;
                    width: calc(~'100% / 7');
                    position: relative;
                    padding-top: 6px;

                    .day-nb {
                        color: #444;
                        font-size: 17px;
                        font-weight: 600;
                        margin-top: 8px;
                    }

                    .available,
                    .unavailable {
                        height: 80px;
                        padding-top: 16px;
                        cursor: pointer;

                        .icon {
                            background-color: #eee;
                            padding: 6px 8px;
                            .border-radius(100%);

                            span {
                                color: #fff;
                            }
                        }

                        .state {
                            margin-top: 10px;
                            height: 25px;
                            line-height: 25px;
                        }
                    }

                    .available {

                        .icon {
                            background: @secondary;
                        }

                        .state {
                            color: @secondary;
                            font-weight: 600;
                        }
                    }

                    .unavailable {

                        .icon {
                            background: #333;
                        }

                        .state {
                            color: #333;
                            font-weight: 600;
                        }
                    }

                    .d-flex {
                        display: flex;
                        align-items: center;
                        position: relative;
                        height: auto;
                        top: -20px;

                        .state {
                            margin: 0 0 0 10px
                        }
                    }

                    .booking-unavailable {
                        position: absolute;
                        right: 10px;
                        top: 9px;

                        .glyphicon {
                            font-size: 16px;
                            color: @main;
                        }
                    }

                    .reservation {
                        height: 79px;
                        bottom: 0;
                        left: 0;
                        width: 100%;

                        .reservation-time {
                            font-weight: 600;
                            font-size: 12px;
                            position: absolute;
                            width: calc(~'100% - 10px');
                            left: 5px;
                            color: #fff;
                            padding: 5px;
                            cursor: pointer;
                        }

                        .reservation-time.start {
                            background-color: @checkin;
                            top: 34px;
                        }

                        .reservation-time.end {
                            background-color: @secondary;
                            bottom: 4px;
                        }

                        .line {
                            background-color: @main;
                            height: 12px;
                            position: absolute;
                            left: 0;
                            bottom: 34px;
                            width: 100%;
                            cursor: pointer;
                        }

                        .line-start {
                            right: 0;
                            left: auto;
                            width: 45%;
                            border-radius: 4px 0 0 4px;
                        }

                        .line-end {
                            width: 45%;
                            border-radius: 0 4px 4px 0;
                        }
                    }
                }

                .disabled {
                    background-color: #eee;
                }

                .selected {
                    background-color: #B5D8F3;
                }
            }
        }
    }

    #legend {
        margin: 20px 0;

        .legend-availability, {
            position: relative;
            line-height: 20px;
            padding-left: 60px;
            font-weight: 700;
        }

        .unavailable {
            color: white;
            background-color: #333;
        }

        .available {
            color: white;
            background-color: @secondary;
        }

        .legend-box {
            display: block;
            position: absolute;
            left: 30px;
            top: 3px;
            margin-right: 10px;
            height: 15px;
            width: 15px;
        }

        .legend-box.reservation {
            background-color: @main;
        }
    }
}

@media (max-width: 767px) {

    #availabilities {
        padding: 10px 0 !important;
        .adjusted(100px);

        #calendar {

            .thead {
                display: none;
            }

            .tbody {
                .row {
                    overflow: auto;

                    .day {
                        width: calc(~'100% / 3');
                    }

                    .disabled {
                        display: none;
                    }
                }

            }
        }
    }

}
